import { getCookie } from 'utils';
import moment from 'moment-shortformat';
import { createIntl, createIntlCache, IntlShape } from 'react-intl';

export const LANGUAGE_COOKIE_NAME = 'labxchange_language';

// This is the canonical list of language codes (and associated data)
// that we use on the frontend for locales.
// NOTE: this should be kept in sync with LANGUAGES in backend/labxchange/settings/base.py
export const UILanguages = {
    'en': {
        label: 'English',
        nativeName: 'English',
    },
    'ar-ae': {
        label: 'Arabic',
        nativeName: 'العربية',
    },
    'zh-hans': {
        label: 'Chinese (Simplified)',
        nativeName: '中文 (简体)',
    },
    'nl': {
        label: 'Dutch',
        nativeName: 'Nederlands',
    },
    'fr': {
        label: 'French',
        nativeName: 'Français',
    },
    'de': {
        label: 'German',
        nativeName: 'Deutsch',
    },
    'it': {
        label: 'Italian',
        nativeName: 'Italiano',
    },
    'ja': {
        label: 'Japanese',
        nativeName: '日本語',
    },
    'ka': {
        label: 'Georgian',
        nativeName: 'ქართული',
    },
    'ko': {
        label: 'Korean',
        nativeName: '한국어',
    },
    'pt-br': {
        label: 'Portuguese (Brazil)',
        nativeName: 'Português',
    },
    'es-419': {
        label: 'Spanish (Latin America)',
        nativeName: 'Español',
    },
    'tr-tr': {
        label: 'Turkish',
        nativeName: 'Türkçe',
    },
    'pl': {
        label: 'Polish',
        nativeName: 'Polski',
    },
    'zu': {
        label: 'Zulu',
        nativeName: 'isiZulu',
    },
    'uk': {
        label: 'Ukrainian',
        nativeName: 'Yкраїнська',
    },
    'vi': {
        label: 'Vietnamese',
        nativeName: 'Tiếng Việt',
    },
    'az': {
        label: 'Azerbaijani',
        nativeName: 'Azərbaycan',
    },
    'ro': {
        label: 'Romanian',
        nativeName: 'limba română',
    },
    'ru': {
        label: 'Russian',
        nativeName: 'Pусский',
    },
    'af': {
        label: 'Afrikaans',
        nativeName: 'Afrikaans',
    },
    'bn-bd': {
        label: 'Bengali',
        nativeName: 'বাংলা (Bāŋlā)',
    },
    'sw-ke': {
        label: 'Swahili',
        nativeName: 'Kiswahili',
    },
    'id': {
        label: 'Indonesian',
        nativeName: 'Bahasa Indonesia',
    },
    'ur-pk': {
        label: 'Urdu',
        nativeName: 'اردو (Urdu)',
    },
    'ms-my': {
        label: 'Malay',
        nativeName: 'bahasa Melayu',
    },
    'th': {
        label: 'Thai',
        nativeName: 'ภาษาไทย (Phasa Thai)',
    },
    'tl': {
        label: 'Tagalog',
        nativeName: 'Wikang Tagalog',
    },
    'hi': {
        label: 'Hindi',
        nativeName: 'हिन्दी (Hindi)',
    },
    'mr': {
        label: 'Marathi',
        nativeName: 'मराठी (Marāṭhī)',
    },
    'ta-in': {
        label: 'Tamil',
        nativeName: 'தமிழ் (Tamiḻ)',
    },
    'ne': {
        label: 'Nepali',
        nativeName: 'नेपाली भाषा (Nepālī bhāśā)',
    }
};

export interface UILanguage {
    key: keyof typeof UILanguages;
    label: string;
    nativeName: string;
}

export const DEFAULT_UI_LANGUAGE: UILanguage = {
    key: 'en',
    label: 'English',
    nativeName: 'English',
};

export const UILangCodes = Object.keys(UILanguages) as (keyof typeof UILanguages)[];

export const setLanguageCookie = (value: string) => {
    const languageCookie = `${LANGUAGE_COOKIE_NAME}=${value}; ` +
        `path=/; domain=${window.location.hostname}`;
    document.cookie = languageCookie;
};

/**
 * Extract general language code from UILangCodes dialects
 * example: ar-ae -> ar.
 * This is used in the heuristics to select the closest supported language based on the browser language.
 */
function getMainLanguageCode(code: string) {
    // Replace underscore with dash (en_US -> en-US, ar_ae -> ar-ae)
    const languageCode = code.replace('_', '-');
    return languageCode.split('-')[0].toLowerCase();
}

/**
 * This tries to get a supported language code, given a list of random language codes.
 * Used as a heuristic to pick a language based on the browser language.
 * Can also be used in other places when you have an unknown/untrusted language code,
 * and you need to get the 'closest' supported language if possible.
 */
export function parseSupportedLanguageCode(languageCodes: readonly string[]): (keyof typeof UILanguages)|null {
    for (const code of languageCodes) {
        if (UILangCodes.includes(code as any)) {
            return code as keyof typeof UILanguages;
        }
        for (const supportedLanguage of UILangCodes) {
            if (getMainLanguageCode(code) === getMainLanguageCode(supportedLanguage)) {
                return supportedLanguage;
            }
        }
    }
    return null;
}

/**
 * Find supported browser preferred language
 */
function getBrowserPreferredLanguage() {
    // Get first supported language from browsers preferred language
    const browserLanguage = parseSupportedLanguageCode(navigator.languages);
    // Try to use browser UI languages if list of preferred language is empty
    const browserUILanguage = parseSupportedLanguageCode([navigator.language]);
    return browserLanguage || browserUILanguage;
}

/**
 * This returns the language to be used for the current locale.
 * Takes into account the browser language and the user's saved locale.
 */
export const getSavedLanguage = () => {
    // Language set using the selector (and saved in the cookie) has priority over browser language.
    const lng = getCookie(LANGUAGE_COOKIE_NAME) || getBrowserPreferredLanguage();
    // If the language cookie isn't set, set it to the browser's default language.
    // This ensures that the backend uses the user language during registration
    // and while retrieving messages/data.
    if (!getCookie(LANGUAGE_COOKIE_NAME) && lng) {
        setLanguageCookie(lng);
    }
    return lng ? lng.toLocaleLowerCase() : DEFAULT_UI_LANGUAGE.key;
};

/**
 * This is for getting the native name of a maybe supported language.
 * Only use this if there's a possibility that the languageCode isn't one we support.
 */
export function getLanguageNativeName(languageCode: string): string {
    if (UILangCodes.includes(languageCode as any)) {
        return UILanguages[languageCode as keyof typeof UILanguages].nativeName;
    }
    return 'Unknown';  // TODO: i18n for this string
}

export function handleTranslationError(err: any): void{
    // TODO: setup NewRelic monitoring of frontend
    const allowedErrors = ['MISSING_TRANSLATION', 'FORMAT_ERROR'];
    if (allowedErrors.includes(err.code)) {
      return;
    }
    /// Here we use console.error() instead throw() because
    /// with throw() if there is an error the page gets stuck on loading

    // tslint:disable-next-line: no-console
    console.error(err);
}

/**
 * Sets <HTML> element classes to switch font
 * stack based on active language.
 */
export const selectFontStackForLanguage = () => {
    const language = getSavedLanguage();

    // Options from `src/assets/scss/_fonts.scss`
    let languageClass: 'font-face-ar' | 'font-face-cs' | 'font-face-ea' | 'font-face-vi' | 'font-face-az' | '';

    switch (language) {
        case 'ar-ae':
            languageClass = 'font-face-ar';
            break;
        case 'uk':
            languageClass = 'font-face-cs';
            break;
        case 'vi':
            languageClass = 'font-face-vi';
            break;
        case 'zh-hans':
        case 'ja':
        case 'ko':
            languageClass = 'font-face-ea';
            break;
        case 'az':
            languageClass = 'font-face-az';
            break;
        default:
            languageClass = '';
    }

    // Set class of the main <HTML> element to the language
    // class, so that <language-selector> body applies fonts
    // globally.
    document.documentElement.className = languageClass;
};

const loadMessages = async (localeKey: string) => {
    if (localeKey === 'en')
        return null;
    try {
        return await import(`./compiled-translations/displayMessages.${localeKey}.json`);
    } catch {
        // tslint:disable-next-line: no-console
        console.warn(`Translation file for locale "${localeKey}" not found. Falling back to default.`);
        return null; // Fallback to default language messages
    }
};

const getLocaleKey = () => {
    const preferredLocale = getSavedLanguage();
    for (const key of UILangCodes) {
        if (preferredLocale.startsWith(key) || preferredLocale === key) {
            return key;
        }
    }
    return DEFAULT_UI_LANGUAGE.key;
};

const getLocale: () => UILanguage = () => {

    const key = getLocaleKey();
    const lang = UILanguages[key];
    return {
        ...lang,
        key,
    };
};

export const getMessages: () => Promise<Record<string, string>> = async() => {
    const key = getLocaleKey().slice(0, 2);
    const messages = await loadMessages(key);
    return messages;
};

export const localeInfo: UILanguage = getLocale();
export const locale: keyof typeof UILanguages = localeInfo.key;

moment.locale(locale);

let intl:IntlShape;

const cache = createIntlCache();

export const initializeIntl = (messages: Record<string, string>) => {

    intl = createIntl(
        {
            locale,
            messages,
            onError: handleTranslationError,
        },
        cache
    );

    return intl;
};

// Use this intl object wherever you need i18n in a situation where you can't use WrappedMessage or FormattedMessage.
// (eg. outside of react components, as a child to `<option>`, etc.)
export { intl };
