import { createRoot } from 'react-dom/client';
import React from 'react';
import {
    getMessages,
    initializeIntl
} from './i18n';

const container = document.getElementById('root');
const root = createRoot(container!);

export const renderApp = async () => {
    const messages = await getMessages();
    initializeIntl(messages);
    // Dynamically importing the App component ensures that intl is initialized before any component uses it.
    // Static import will not work here because the intl object is also needed by the modules that are imported in App.tsx
    const App = (await import('./App/App')).App;
    root.render(<App messages={messages} />);
};

renderApp();
